/* Import a modern Google Font */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

.important-dates {
  background: linear-gradient(135deg, #f3f3f5, #f7f7f8);
  padding: 40px;
  color: white;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  margin: 0; /* Set to 0 for full width */
  width: 100%; /* Set to 100% for full width */
}

.important-dates h2 {
  font-size: 2.5rem;
  margin-bottom: 30px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 600;
  color: #181717;
}

.dates-table {
  width: 100%; /* Change to 100% for full width */
  margin: 0 auto; /* Center the table */
  border-collapse: separate;
  border-spacing: 0;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.dates-table th, .dates-table td {
  padding: 20px;
  text-align: center;
  color: black;
}

.dates-table th {
  background-color: #aca6b1;
  color: #f7f7f7;
  font-weight: 500;
  text-transform: uppercase;
}

.dates-table td {
  background-color: rgba(255, 255, 255, 0.1);
  color: #1c1b1b;
  font-weight: 500;
  transition: background 0.3s, color 0.3s;
}

/* Hover effect for table rows */
.dates-table tr:hover td {
  background-color: rgba(102, 16, 242, 0.2);
  color: #000000;
}

/* Responsive Design */

/* For larger screens and tablets */
@media (max-width: 1200px) {
  .important-dates h2 {
    font-size: 2.2rem;
  }

  .dates-table th, .dates-table td {
    padding: 15px;
  }
}

/* For medium screens (tablets in portrait mode) */
@media (max-width: 992px) {
  .important-dates {
    padding: 30px;
  }

  .important-dates h2 {
    font-size: 2rem;
  }

  .dates-table {
    width: 100%; /* Keep full width */
  }

  .dates-table th, .dates-table td {
    padding: 12px;
    font-size: 1rem;
  }
}

/* For smaller screens (mobile devices) */
@media (max-width: 768px) {
  .important-dates {
    padding: 20px;
  }

  .important-dates h2 {
    font-size: 1.8rem;
  }

  .dates-table {
    width: 100%; /* Keep full width */
  }

  .dates-table th, .dates-table td {
    padding: 10px;
    font-size: 0.9rem;
  }
}

/* For extra-small screens (small mobile devices) */
@media (max-width: 576px) {
  .important-dates {
    padding: 15px;
  }

  .important-dates h2 {
    font-size: 1.5rem;
  }

  .dates-table th, .dates-table td {
    padding: 8px;
    font-size: 0.85rem;
  }
}
