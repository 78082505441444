.products-container {
    padding: 20px;
    text-align: center;
  }
  
  .heading {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 80px;
    padding: 20px;
  }
  
  .product-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s;
    position: relative;
  }
  
  .product-card:hover {
    transform: translateY(-10px);
  }
  
  .product-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .product-info {
    padding: 15px;
    text-align: left;
  }
  
  .product-info h4 {
    color: #a27126;
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  .product-info h3 {
    color: #333;
    font-size: 18px;
    font-weight: 600;
  }
  
  @media (max-width: 768px) {
    .product-card {
      width: 100%;
    }
  }
  