body {
    /* font-family: 'Roboto', sans-serif; */
    /* font-family: "Poppins", sans-serif; */
  /* font-weight: 500; */
  font-style: normal;

    font-family: 'Open Sans', sans-serif; /* Body font */
    
      overflow: auto;
    
    

  }
  
  .header {
    font-family: 'Montserrat', sans-serif; /* Header font */
  }
