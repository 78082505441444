nav {
  position: fixed; /* Fixed positioning for the navbar */
  top: 0; /* Aligns navbar to the top */
  width: 100%;
  z-index: 100;
  transition: background-color 0.3s ease, padding 0.3s ease;
}

.hello {
  height: 100vh;
  width: 100%;
}

.sticky-nav {
  background-color: rgb(53, 51, 51); /* Background color when sticky */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.new-badge {
  font-size: 12px;
  color: #ebe6daf3; /* Neon pink color */
  font-weight: bold;
  padding: 2px 6px;
  background-color: #dc290d;
  animation: lipLop 0.5s ease-in-out infinite alternate, glow 1.5s ease-in-out infinite alternate;
  text-shadow: 0 0 5px rgba(255, 0, 255, 0.8), 0 0 10px rgba(255, 0, 255, 0.6);
  border-radius: 5px;
}

@keyframes glow {
  from {
    text-shadow: 0 0 10px #ff00ff, 0 0 20px #ff00ff, 0 0 30px #ff00ff;
  }
  to {
    text-shadow: 0 0 15px #8000ff, 0 0 25px #8000ff, 0 0 40px #8000ff; /* Transition to neon purple */
  }
}

@keyframes lipLop {
  0%, 100% {
    opacity: 1; /* Fully visible */
  }
  50% {
    opacity: 0; /* Invisible (blink) */
  }
}

.container1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px; /* Horizontal padding */
  color: black;
  background-color: white;
  max-width: 100vw;
  height: 5vh;
}

img {
  height: 90px; /* Image height */
  width: 180px; /* Image width */
  border-radius: 15px; /* Rounded corners */
  margin-left: 50px; /* Left margin */
}

/* Base nav link styles */
.nav-links {
  list-style: none; /* Remove list styling */
  display: flex; /* Flexbox for alignment */
  align-items: center;
  gap: 1rem; /* Space between nav items */
  transition: all 0.3s ease;
}

.nav-links li button {
  font-size: 16px; /* Button font size */
  color: black; /* Button text color */
  background: none; /* No background */
  border: none; /* No border */
  cursor: pointer; /* Pointer cursor */
  padding: 5px 15px; 
  transition: color 0.3s ease, background-color 0.3s ease;
}

.nav-links li button:hover {
  color: rgb(8, 8, 8); /* Hover color */
  background-color: #f99a52; /* Hover background */
}

/* Hamburger icon styles */
.hamburger {
  display: none; /* Hidden by default */
  font-size: 64px; /* Icon size */
  cursor: pointer; /* Pointer cursor */
  color: rgb(0, 0, 0); /* Icon color */
  background: none; /* No background */
  border: none; /* No border */
}

/* Hamburger styles for small screens */
@media (max-width: 640px) {
  .hamburger {
    font-size: 40px; 
    width: 50px;
    height: 50px; /* Set a height for better click area */
    background-color: rgb(85, 71, 65);
    border-radius: 8px; /* Use a more standard border-radius */
    color: white; /* Change text color to white for better contrast */
    border: none; /* Remove default border */
    cursor: pointer; /* Change cursor to pointer */
    display: flex; /* Center the icon */
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
    transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transition */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow for depth */
  }
}

/* Sidebar styles */
.sidebar {
  position: fixed; /* Fixed positioning for sidebar */
  top: 0;
  right: -300px; /* Start off-screen */
  width: 270px; /* Width of the sidebar */
  height: 100%; /* Full height */
  background-color: rgba(83, 78, 78, 0.9); /* Background color */
  transition: right 0.3s ease; /* Smooth transition */
  z-index: 200; /* Ensure it's above other content */
  color: white;
}

.sidebar.show {
  right: 0; /* Slide in when 'show' class is added */
}

/* Responsive adjustments for tablets and smaller screens */
@media (max-width: 1024px) {
  .nav-links {
    flex-direction: column; /* Stack items vertically */
    gap: 1rem; /* Space between items */
    text-align: center; /* Center text */
    padding: 1rem 0; /* Vertical padding */
    position: absolute; /* Position absolute */
    top: 56px; /* Position below the navbar */
    right: 0; /* Align right */
    background-color: rgba(83, 78, 78, 0.9); /* Background for mobile */
    width: 100%; /* Full width */
    display: none; /* Hidden by default */
  }
  
  .nav-links.show {
    display: flex; /* Show when 'show' class is added */
  }

  .hamburger {
    display: block; /* Show hamburger on smaller screens */
  }

  button {
    font-size: 1rem; /* Adjust button font size */
    padding: 10px; /* Adjust button padding */
  }
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .container1 {
    flex-direction: row; /* Maintain row direction */
    padding: 0 10px; /* Reduced padding */
  }

  img {
    height: 60px; /* Smaller image for mobile */
    width: 120px; /* Smaller width */
    margin-left: 0; /* Remove left margin */
  }

  .nav-links {
    flex-direction: column; /* Stack items vertically */
    gap: 0.6rem; /* Space between items */
    padding: 1rem; 
    color: white; /* Vertical padding */
  }

  .nav-links li button {
    font-size: 14px;
    color: white;
    font-weight: 500;
    text-align: center; /* Smaller button font size */
  }

  .hamburger:hover {
    color: #f99a52; /* Hover color */
  }
}

/* Ultra small screens */
@media (max-width: 480px) {
  .nav-links {
    font-size: 12px; /* Adjust font size */
  }

  img {
    height: 50px; /* Smaller image */
    width: 100px; /* Smaller width */
  }
}

/* Conference Page Styles */
.selected {
  color: #ef4444;
  font-weight: 600;
  border: 1px solid #ef4444;
  border-radius: 0.5rem;
  padding: 0.5rem;
}

.selected-box {
  transform: scale(1.05);
  transition: transform 0.2s;
  color: #ef4444;
  font-weight: 600;
}

/* Dropdown Menu Styles */
.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}

.group .dropdown-content {
  display: none;
}

.group:hover .dropdown-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.dropdown-content .flex {
  display: flex;
  flex-wrap: wrap;
}

.dropdown-content .flex > div {
  flex: 1 1 50%;
  padding: 10px;
}

.dropdown-content .grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  width: 100%;
}
/* Add these styles to your Home.css file */


/* Dropdown styles */
/* Add these styles to your Home.css file */
/* Add these styles to your Home.css file */

/* Add these styles to your Home.css file */
/* Add these styles to your Home.css file */
/* Add these styles to your Home.css file */
/* Add these styles to your Home.css file */

/* .container1 {
  max-width: 1440px;
  width: 100vw;
  height:10vh;
  margin: 0 auto;
} */

.nav-links {
  gap: 1.5rem;
}

/* Dropdown styles */
.nav-links .group:hover .absolute {
  display: flex;
  width: max-content;
  min-width: 100px;
  left: 50%;
  transform: translateX(-50%);
  background: #f5f3f3;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  z-index: 50;
}

.dropdown-container {
  display: flex;
  width: 100%;
  
  /* box-shadow: 10px 9px 8px 10px inset  #e1d2d2; */
}

.menu-section {
  width: 300px;
  padding: 1.5rem;
  background: rgb(242, 224, 187);
  /* box-shadow: 7px 5px 10px  8px inset  #e1d2d2; */

} 

.menu-section ul {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.menu-section ul li a {
  display: block;
  padding: 0.5rem;
  transition: all 0.2s;
}

.menu-section ul li a:hover {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 0.25rem;
}

.cards-section {
  flex: 1;
  padding: 1.1rem;
  display: grid;
  /* width: 1px ; */
  grid-template-columns: repeat(2, 1fr);
  gap:1rem ;
}

.menu-card {
  height: 160px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  transition: transform 0.2s;
  padding:1rem;
}

.menu-card:hover {
  transform: translateY(-2px);
}

/* Mobile menu styles */
@media (max-width: 1024px) {
  .nav-links {
    display: none;
  }
  
  .nav-links.show {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    /* background: white; */
    padding: 1rem;
  }
  
  .dropdown-container {
    flex-direction: column;
  }

  .menu-section {
    width: 100%;
  }
  
  .cards-section {
    grid-template-columns: 1fr;
  }
}
.conference-menu {
  position: relative;
}

.conference-toggle {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
  background: none;
  border: none;
  color: inherit;
}

.dropdown-arrow {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.5rem;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid currentColor;
  transition: transform 0.2s ease;
}

.dropdown-arrow.open {
  transform: rotate(180deg);
}

.conference-dropdown {
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  min-width: 600px;
}

.conference-dropdown.show {
  display: block;
}

/* Mobile styles */ 
@media (max-width: 1024px) {
  .conference-dropdown {
    position: static; /* Change to static for mobile */
    min-width: 100%; /* Full width on mobile */
    background: transparent; /* Transparent background */
    box-shadow: none; /* No shadow */
    margin-top: 0.5rem; /* Margin for spacing */
  }

  .dropdown-container {
    flex-direction: column; /* Stack items vertically */
    background: rgba(255, 255, 255, 0.05); /* Light background */
    border-radius: 0.5rem; /* Rounded corners */
    overflow: hidden; /* Hide overflow */
  }

  .menu-section {
    width: 100%; /* Full width */
    padding: 1rem; /* Padding for the section */
    background: rgba(242, 224, 187, 0.9); /* Light background */
  }

  .menu-section ul li a {
    color: black; /* Text color */
    padding: 0.75rem; /* Padding for links */
    border-radius: 0.25rem; /* Rounded corners */
  }

  .menu-section ul li a:hover {
    background: rgba(0, 0, 0, 0.1); /* Hover effect */
  }

  .cards-section {
    padding: 1rem; /* Padding for cards */
    gap: 0.75rem; /* Space between cards */
  }

  .menu-card {
    height: auto; /* Auto height */
    padding: 1rem; /* Padding for cards */
    background: rgba(255, 255, 255, 0.9); /* Light background */
    color: black; /* Text color */
  }
}

/* Small mobile styles */
@media (max-width: 640px) {
  .conference-dropdown {
    margin: 0; /* Remove margin */
  }

  .dropdown-container {
    flex-direction:row; /* Stack items vertically */
    background: rgba(255, 255, 255, 0.05); /* Light background */
    border-radius: 0.5rem; /* Rounded corners */
    overflow: hidden; /* Hide overflow */
    /* height: 50vh; */
    width: 40vh;
  }
  .menu-section {
    /* padding: 0.5rem; Padding for section */
  }

  .menu-section ul {
    gap: 0.3rem; /* Space between items */
  }

  .cards-section {
    /* padding: 0.5rem; Padding for cards */
    gap: 0.5rem; /* Space between cards */
  }

  .menu-card {
    padding: 0.75rem; /* Padding for cards */
  }

  .menu-card i {
    display: none; /* Hide icons on small screens */
  }

  .menu-card span {
    font-size: 0.875rem; /* Smaller font size */
  }
}

/* Animation for dropdown */
@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.conference-dropdown.show {
  animation: slideDown 0.2s ease-out forwards;
}

/* Ensure dropdown is scrollable on mobile */
@media (max-width: 1024px) {
  .conference-dropdown {
    max-height: 80vh; /* Limit height */
    overflow-y: auto; /* Enable scrolling */
  }
}