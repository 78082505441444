.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    gap: 20px;
  }
  
  .card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 100%;
    max-width: 600px;
    text-align: center;
  }
  
  .title {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }
  
  .form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .fileInput {
    margin-bottom: 10px;
  }
  
  .submitButton {
    background: #007bff;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    font-size: 1rem;
    padding: 10px 20px;
  }
  
  .submitButton:hover {
    background: #0056b3;
  }
  
  .downloadList {
    list-style: none;
    padding: 0;
  }
  
  .downloadItem {
    margin: 10px 0;
  }
  
  .downloadLink {
    color: #007bff;
    text-decoration: none;
  }
  
  .downloadLink:hover {
    text-decoration: underline;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .container {
      padding: 10px;
    }
  
    .card {
      padding: 15px;
    }
  
    .title {
      font-size: 1.25rem;
    }
  
    .submitButton {
      font-size: 0.875rem;
      padding: 8px 16px;
    }
  }
  