.university-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 20px;
  background-color: #f5f5f5;
}

.university-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px;
  width: 150px;
  text-align: center;
}

.Client {
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 20px;
}

.university-scroll-wrapper {
  display: flex;
  gap: 30px;
  white-space: nowrap;
  overflow-x: auto;
  scroll-behavior: smooth;
  padding: 10px 0;
  touch-action: pan-x; /* Enable horizontal touch scrolling */
}

.university-logo {
  width: 150px;
  height: 150px;
  margin-bottom: 10px;
  transition: transform 0.3s ease;
}

.university-logo:hover {
  transform: scale(1.1);
}

.university-name {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

/* Responsive Design */

/* Tablets */
@media (max-width: 1024px) {
  .university-item {
    width: 120px;
  }

  .university-logo {
    width: 120px;
    height: 120px;
  }

  .Client {
    font-size: 26px;
  }

  .university-name {
    font-size: 14px;
  }
}

/* Mobile phones */
@media (max-width: 768px) {
  .university-item {
    width: 100px;
  }

  .university-logo {
    width: 100px;
    height: 100px;
  }

  .Client {
    font-size: 22px;
  }

  .university-name {
    font-size: 12px;
  }
}

/* Small mobile phones */
@media (max-width: 480px) {
  .university-item {
    width: 80px;
  }

  .university-logo {
    width: 80px;
    height: 80px;
  }

  .Client {
    font-size: 18px;
  }

  .university-name {
    font-size: 10px;
  }
}
