/* General Styling */
body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

/* Contact Page Background */
.contact-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: rgba(66, 65, 65, 0.6);
  padding: 20px;
  overflow: auto;
}

.map-container {
  margin-bottom: 20px;
}

.map-clickable {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
  font-size: 18px;
  margin-bottom: 20px;
}

.map-clickable:hover {
  color: darkblue;
}

/* Contact Container */
.contact-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  max-width: 1200px;
  width: 100%;
}

/* Form Container */
.form-container {
  background: white;
  padding: 20px; /* Reduced padding */
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  flex: 1;
  min-width: 300px;
  max-width: 600px;
  animation: fadeIn 1s ease-in-out;
  scroll-margin-top: 10px; /* To avoid button focus causing scroll jump */
}

/* Location Container */
.location-container {
  flex: 1;
  min-width: 300px;
  max-width: 600px;
  background: white;
  padding: 20px; /* Reduced padding */
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.location-heading {
  font-size: 2rem;
  margin-bottom: 20px;
}

/* Google Maps Iframe */
.map {
  width: 100%;
  height: 250px; /* Reduced height */
  border: none;
  border-radius: 10px;
  margin-bottom: 20px;
}

/* Contact Details */
.contact-details {
  font-size: 1rem;
  color: #111111;
  text-align: left;
  font-weight: 500;
}

/* Heading */
.form-heading {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
  text-transform: uppercase;
}

/* Form Field Styling */
.contact-form .form-group {
  position: relative;
  margin-bottom: 20px;
}

.contact-form .form-group input,
.contact-form .form-group textarea {
  width: 100%;
  padding: 15px 45px;
  font-size: 1rem;
  border: 2px solid #ddd;
  border-radius: 10px;
  outline: none;
  transition: border 0.3s ease, background-color 0.3s ease;
  background-color: #f9f9f9;
}

/* Icons Inside Inputs */
.input-icon {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #aaa;
  font-size: 1.2rem;
}

.contact-form .form-group input:focus,
.contact-form .form-group textarea:focus {
  border-color: #007bff;
  background-color: #fff;
}

.contact-form .form-group textarea {
  resize: none;
  height: 150px;
}

/* Button Styling */
.submit-btn {
  width: 100%;
  padding: 15px;
  background-color: #f99a52;
  color: white;
  border: none;
  border-radius: 30px;
  font-size: 1.2rem;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.submit-btn:hover {
  background-color: #f99a52;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  transform: translateY(-3px);
}

/* Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .contact-container {
    flex-direction: column;
    align-items: center;
  }

  .form-container,
  .location-container {
    width: 100%;
  }

  .map {
    height: 200px;
  }

  .submit-btn {
    padding: 12px;
  }
}
