.footer {
  height: 10vh;
  width: 100%;
  background-color: black;
  color: rgb(247, 243, 243);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* Paragraph Text Styling */
.footer-text {
  margin: 0;
  text-align: center;
  font-size: 1rem;
}
