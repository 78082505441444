.hero1 {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Ensures content stays centered */
}

.hero-section {
  background-image: url('./image/conference2.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 80vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: white;
  text-align: center;
  animation: fadeIn 1s ease-in-out;
  padding: 1rem; /* Add padding to prevent content from touching the edges */
}

.hero-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.hero-content {
  position: relative;
  z-index: 2;
  padding: 1.5rem 2rem; /* Adds padding for spacing */
  max-width: 800px; /* Limit the width for better readability */
}

.conference-title {
  font-size: 2rem;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
}

.conference-subtitle {
  width: 98%; /* Adjust width for smaller screens */
  margin: 0 auto;
  font-size: 16px;
  font-weight: 500;
  margin-top: 0.5rem;
  font-family: 'Roboto', sans-serif;
  text-align: center;
}

.btns {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.cta-btn1 {
  padding: 0.7rem 3rem;
  font-size: 16px;
  font-weight: 700;
  background-color: #f99a52;
  border-radius: 10px;
  transition: background-color 0.3s ease;
  color: black;
}

.cta-btn1:hover {
  background-color: #33567c;
  color: white;
}

/* Media query for extra small devices (phones, 320px and up) */
@media (max-width: 576px) {
  .conference-title {
    font-size: 1.5rem;
  }

  .conference-subtitle {
    font-size: 14px;
    width: 90%;
  }

  .cta-btn1 {
    padding: 0.4rem 2rem;
    font-size: 14px;
  }

  .hero-section {
    padding: 3rem 1rem;
    min-height: 50vh;
  }
}

/* Media query for small devices (tablets, 768px and up) */
@media (min-width: 576px) and (max-width: 768px) {
  .conference-title {
    font-size: 1.8rem;
  }

  .conference-subtitle {
    font-size: 14px;
  }

  .hero-section {
    min-height: 60vh;
  }
}

/* Media query for medium devices (small laptops, 992px and up) */
@media (min-width: 768px) and (max-width: 992px) {
  .conference-title {
    font-size: 2rem;
  }

  .conference-subtitle {
    font-size: 16px;
  }

  .hero-section {
    min-height: 55vh;
  }
}

/* Media query for large devices (desktops, 1200px and up) */
@media (min-width: 992px) and (max-width: 1200px) {
  .conference-title {
    font-size: 2.5rem;
  }

  .conference-subtitle {
    font-size: 18px;
  }

  .hero-section {
    min-height: 60vh;
  }
}
