.achievements-container {
  padding: 20px;
  text-align: center;
  background-color: rgba(10, 10, 10, 0.6);
  width: 100%;
  overflow: hidden; /* Hide overflow of the main container */
}

.achievements-title {
  font-size: 2rem;
  margin-bottom: 20px;
  color: white;
  font-weight: 600;
}

.scroll-container {
  display: flex;
  overflow-x: auto; /* Allow horizontal scrolling */
  overflow-y: hidden; /* Prevent vertical scrolling */
  width: 100%;
  height: 300px; /* Fixed height for the scrolling area */
  gap: 15px; /* Space between achievement cards */
  padding: 10px; /* Padding inside the scroll container */
  scrollbar-width: thin; /* Firefox scrollbar styling */
  scrollbar-color: rgba(255, 255, 255, 0.5) transparent; /* Firefox scrollbar styling */
}

/* For Webkit browsers (Chrome, Safari) */
.scroll-container::-webkit-scrollbar {
  height: 8px; /* Height of horizontal scrollbar */
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.5); /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners */
}

.scroll-container::-webkit-scrollbar-track {
  background: transparent; /* Background of the scrollbar track */
}

.achievement-card {
  flex: 0 0 auto; /* Prevent cards from shrinking */
  width: 300px; /* Width of each card */
  background-color: rgba(108, 107, 107, 0.8);
  border-radius: 10px;
  padding: 15px;
  text-align: center;
}

.achievement-image {
  width: 100%; /* Full width of the card */
  height: 200px; /* Fixed height */
  border-radius: 8px;
  object-fit: cover; /* Cover the card without stretching */
  margin-bottom: 10px; /* Space between image and title */
}

.achievement-title {
  font-size: 1rem; /* Font size of the title */
  color: white; /* Title color */
}

@media screen and (max-width: 600px) {
  .achievement-card {
    width: 150px; /* Width for mobile */
  }
  .achievement-title {
    font-size: 0.8rem; /* Smaller title font for mobile */
  }
  .achievement-image {
    height: 120px; /* Smaller image height for mobile */
  }
}
